import { i18n } from "@/locale";
const { t: $t } = i18n.global;

export default [
    {
        path: "/common",
        name: "common__root__",
        redirect: {
            name: "common-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "common",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "common-index",
                component: () => import("@/views/common/index.vue"),
                meta: {
                    title: $t("nav.control"),
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/common/account",
        name: "common_account",
        meta: {
            title: $t("nav.common.account.name"),
            icon: "user",
            parent: "common",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/common/account/setting",
        children: [
            {
                path: "setting",
                name: "common-account-setting",
                component: () => import("@/views/common/account/setting.vue"),
                meta: {
                    title: $t("nav.common.account.profile"),
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "message",
                name: "common-account-message",
                component: () => import("@/views/common/account/message.vue"),
                meta: {
                    title: $t("nav.common.account.message"),
                    icon: "message",
                    affix: false,
                },
            },
            {
                path: "address",
                name: "common-account-address",
                component: () => import("@/views/common/account/address.vue"),
                meta: {
                    title: $t("nav.common.account.address"),
                    icon: "address-mng",
                    affix: false,
                },
            },
            {
                path: "wechat-bind",
                name: "common-account-wechat-bind",
                component: () => import("@/views/common/account/wechat-bind.vue"),
                hidden: true,
                meta: {
                    title: $t("nav.common.account.wechat_bind"),
                    icon: "message",
                    affix: false,
                    activeMenu: "/common/account/setting",
                },
            },
            {
                path: "feedback",
                name: "common-account-feedback",
                component: () => import("@/views/common/account/my-feedback.vue"),
                hidden: true,
                meta: {
                    title: "反馈工单",
                    icon: "feedback",
                    affix: false,
                },
            },
            {
                path: "feedback-detail/:id(\\d+)",
                name: "common-account-feedback-detail",
                hidden: true,
                component: () => import("@/views/dev/support/feedback-detail.vue"),
                meta: {
                    title: "反馈工单",
                    icon: "feedback",
                    affix: false,
                    activeMenu: "/common/account/feedback",
                },
            },
        ],
    },
];
