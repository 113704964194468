import store from "@/store";
export default [
    {
        path: "/finance",
        name: "finance__root__",
        redirect: {
            name: "finance-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "finance",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "finance-index",
                component: () => import("@/views/pms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/finance/bill",
        name: "finance-bill",
        meta: {
            title: "账单管理",
            icon: "order-bill",
            parent: "finance",
            permission: "manage_bill",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/finance/bill/order-collection",
        children: [
            {
                path: "order-collection",
                name: "finance-bill-order-collection",
                component: () => import("@/views/fms/bill/order-collection.vue"),
                meta: {
                    title: "签单收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "collection-detail/:id(\\d+)?",
                name: "finance-bill-collection-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/collection-detail.vue"),
                meta: {
                    title: "收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/finance/bill/order-collection",
                },
            },
            {
                path: "oem-detail/:id(\\d+)?",
                name: "finance-bill-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/oem-detail.vue"),
                meta: {
                    title: "OEM收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/finance/bill/oem",
                },
            },
            {
                path: "oem",
                name: "finance-bill-oem",
                component: () => import("@/views/fms/bill/oem.vue"),
                meta: {
                    title: "OEM收款",
                    icon: "oem_pay",
                    affix: false,
                },
            },
            {
                path: "statement",
                name: "finance-bill-statement",
                component: () => import("@/views/fms/bill/statement.vue"),
                meta: {
                    title: "线下收款",
                    icon: "bank",
                    affix: false,
                },
            },
            {
                path: "pay",
                name: "finance-bill-pay",
                component: () => import("@/views/fms/bill/pay.vue"),
                meta: {
                    title: "线上支付",
                    icon: "pay",
                    affix: false,
                },
            },
            {
                path: "pay/eurofins/:id(\\d+)?",
                name: "finance-bill-pay-eurofins",
                hidden: true,
                component: () => import("@/views/eurofins/sensing/order-detail.vue"),
                meta: {
                    title: "订单详情",
                    key: "bill",
                    activeMenu: "/finance/bill/pay",
                },
            },
            // {
            //     path: "invoice",
            //     name: "finance-bill-invoice",
            //     component: () => import("@/views/fms/bill/invoice.vue"),
            //     meta: {
            //         title: "发票中心",
            //         icon: "invoice",
            //         affix: false,
            //     },
            // },
        ],
    },
    {
        path: "/finance/invoice",
        name: "finance-invoice",
        meta: {
            title: "发票管理",
            icon: "invoice",
            parent: "finance",
            permission: "manage_invoice",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/finance/invoice/invoice_pending",
        children: [
            {
                path: "pending",
                name: "finance-invoice-pending",
                component: () => import("@/views/fms/invoice/pending.vue"),
                meta: {
                    title: "待开发票",
                    icon: "invoice-pending",
                    affix: false,
                },
            },
            {
                path: "complete",
                name: "finance-invoice-complete",
                component: () => import("@/views/fms/invoice/complete.vue"),
                meta: {
                    title: "已开发票",
                    icon: "invoice-complete",
                    affix: false,
                },
            },
            {
                path: "reject",
                name: "finance-invoice-reject",
                component: () => import("@/views/fms/invoice/reject.vue"),
                meta: {
                    title: "拒开记录",
                    icon: "invoice-reject",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/finance/exception",
        name: "finance-exception",
        meta: {
            title: "系统管理",
            icon: "system",
            parent: "finance",
            permission: "manage_bill",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/finance/exception/exception",
        children: [
            {
                path: "exception",
                name: "finance-bill-exception",
                component: () => import("@/views/fms/bill/exception.vue"),
                meta: {
                    title: "异常处理",
                    icon: "exception",
                    affix: false,
                },
            },
        ],
    },
];
