export default [
    {
        path: "/pms",
        name: "pms__root__",
        redirect: {
            name: "pms-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "pms",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "pms-index",
                component: () => import("@/views/pms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/pms/customer",
        name: "pms-customer",
        meta: {
            title: "客户管理",
            icon: "enterprise",
            parent: "pms",
            permission: "manage_customer",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/customer/organization",
        children: [
            {
                path: "organization",
                name: "pms-enterprise-organization",
                component: () => import("@/views/omp/enterprise/organization.vue"),
                meta: {
                    title: "企业管理",
                    icon: "organization",
                },
            },
            {
                path: "dashboard",
                name: "pms-enterprise-dashboard",
                component: () => import("@/views/omp/enterprise/dashboard.vue"),
                meta: {
                    title: "园区管理",
                    icon: "dashboard_mng",
                },
            },
            {
                path: "user",
                name: "pms-account-user",
                component: () => import("@/views/omp/account/user.vue"),
                meta: {
                    title: "账号管理",
                    icon: "users",
                },
            },
            {
                path: "other",
                name: "pms-account-other",
                component: () => import("@/views/omp/account/other-user.vue"),
                meta: {
                    title: "散户管理",
                    icon: "user",
                },
            },
            {
                path: "address",
                name: "pms-customer-address",
                component: () => import("@/views/fms/customer/address.vue"),
                meta: {
                    title: "地址管理",
                    icon: "address-mng",
                },
            },
        ],
    },
    {
        path: "/pms/general",
        name: "finance-general",
        meta: {
            title: "产品管理",
            icon: "order-collection",
            parent: "pms",
            permission: "manage_general",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/general/goods_price",
        children: [
            {
                path: "goods_price",
                name: "finance-mall-goods-price",
                component: () => import("@/views/fms/mall/goods_price.vue"),
                meta: {
                    title: "产品定价",
                    icon: "discount",
                    affix: false,
                },
            },
            {
                path: "goods_price_detail/:id(\\d+)?",
                name: "finance-mall-goods-price-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_price_detail.vue"),
                meta: {
                    title: "定价详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/pms/general/goods_price",
                },
            },
            {
                path: "sim",
                name: "finance-general-sim",
                component: () => import("@/views/fms/general/sim.vue"),
                meta: {
                    title: "流量套餐",
                    icon: "expansion",
                    affix: false,
                },
            },
            {
                path: "package",
                name: "finance-general-titan-package",
                component: () => import("@/views/fms/general/titan-package.vue"),
                meta: {
                    title: "泰坦套餐",
                    icon: "expansion",
                    affix: false,
                },
            },
            {
                path: "expansion",
                name: "finance-general-expansion-system",
                component: () => import("@/views/fms/general/expansion-system.vue"),
                meta: {
                    title: "拓展系统",
                    icon: "expansion",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/pms/dbmanage",
        name: "dev-dbmanage",
        meta: {
            title: "数据管理",
            icon: "data",
            parent: "pms",
            permission: "manage_data",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/dbmanage/album",
        children: [
            {
                path: "album",
                name: "dev-dbmanage-album",
                component: () => import("@/views/fms/dbmanage/album.vue"),
                meta: {
                    title: "相册数据",
                    icon: "album",
                },
            },
            {
                path: "aigrow",
                name: "dev-dbmanage-aigrow",
                component: () => import("@/views/omp/aigrow/stat/index.vue"),
                meta: {
                    title: "识别数据",
                    icon: "aigrow",
                },
            },
            {
                path: "device",
                name: "dev-dbmanage-device",
                component: () => import("@/views/fms/dbmanage/device.vue"),
                meta: {
                    title: "设备数据",
                    icon: "device",
                },
            },
        ],
    },
    {
        path: "/pms/support",
        name: "support",
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/support/feedback",
        meta: {
            title: "支持中心",
            icon: "support",
            parent: "pms",
        },
        children: [
            {
                path: "feedback",
                name: "dev-support-feedback",
                component: () => import("@/views/dev/support/feedback.vue"),
                meta: {
                    title: "反馈管理",
                    icon: "feedback",
                    permission: "manage_feedback",
                },
            },
            {
                path: "feedback-detail/:id(\\d+)",
                name: "dev-support-feedback-detail",
                component: () => import("@/views/dev/support/feedback-detail.vue"),
                hidden: true,
                meta: {
                    title: "反馈管理",
                    icon: "feedback",
                    permission: "manage_feedback",
                    activeMenu: "/pms/support/feedback",
                },
            },
        ],
    },
    {
        path: "/pms/account",
        name: "account",
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/account/list",
        meta: {
            title: "系统账号",
            icon: "admin",
            parent: "pms",
            permission: "manage_admin_account",
        },
        children: [
            {
                path: "list",
                name: "account-list",
                component: () => import("@/views/dev/account/list.vue"),
                meta: {
                    title: "账号配置",
                    icon: "users",
                    app: "cms",
                },
            },
            {
                path: "role",
                name: "account-role",
                component: () => import("@/views/dev/account/role.vue"),
                meta: {
                    title: "角色配置",
                    icon: "role",
                    group: 512,
                },
            },
            {
                path: "permission",
                name: "account-permission",
                component: () => import("@/views/dev/account/permission.vue"),
                meta: {
                    title: "权限配置",
                    icon: "group",
                    group: 512,
                },
            },
        ],
    },

    {
        path: "/pms/log",
        name: "dev-log",
        meta: {
            title: "系统管理",
            icon: "setting",
            parent: "pms",
            permission: "manage_system_log",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "dev-system-app",
        },
        children: [
            {
                path: "service-log",
                name: "cms-service-log",
                component: () => import("@/views/dev/system/service_log/index.vue"),
                meta: {
                    title: "后端日志",
                    icon: "terminal",
                },
            },
            {
                path: "sms-log",
                name: "omp-sms-log",
                component: () => import("@/views/omp/system/sms_log.vue"),
                meta: {
                    title: "短信日志",
                    icon: "sms",
                },
            },
            {
                path: "dev-action-log",
                name: "dev-action-log",
                component: () => import("@/views/dev/system/action_log.vue"),
                meta: {
                    title: "操作日志（后台）",
                    icon: "log",
                },
            },
            {
                path: "omp-action-log",
                name: "omp-action-log",
                component: () => import("@/views/omp/system/action_log.vue"),
                meta: {
                    title: "操作日志（用户）",
                    icon: "log",
                },
            },
            {
                path: "cms-upload-log",
                name: "cms-upload-log",
                component: () => import("@/views/dev/system/upload_log.vue"),
                meta: {
                    title: "上传日志（后台）",
                    icon: "upload",
                },
            },
            {
                path: "omp-upload-log",
                name: "omp-upload-log",
                component: () => import("@/views/omp/system/upload_log.vue"),
                meta: {
                    title: "上传日志（用户）",
                    icon: "upload",
                },
            },
        ],
    },
];
