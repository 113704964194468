export default {
    title: "OEM Manufacturing",

    status: {
        all: "All",
        draft: "Order to be confirmed",
        draft_complete: "Order confirmed",
        unpaid: "Payment pending",
        unpaid_complete: "Payment completed",
        arrange: "Production arranging",
        arrange_complete: "Production arranged",
        production: "In production",
        production_complete: "Production completed",
        shipping: "Shipping",
        shipping_complete: "Goods received",
        final_payment: "Final payment pending",
        final_payment_complete: "Payment settled",
        completed: "Completed",
        close: "Close",
    },

    common: {
        add_button: "Add",
        confirm_button: "Confirm",
        remove_button: "Remove",
        cancel_button: "Cancel",

        custom: "Custom",
        removed: "Removed",
        add: "Increase",
        sub: "Decrease",
        deduction: "Deduction",

        official_files: "Official Files",
        produce_plan: "Produce Plan",
        category: "Category",
        product: "Product",
        version: "Version",
        sn: "Code",
        quantity: "Quantity",
        parts: "Parts",
        accessories: "Accessories",
        unit_use: "Unit Usage",
        total_use: "Total Usage",
        unit_price: "Unit Price",
        total_price: "Total Price",
        design_files: "Design Files",
        remark: "Remark",
        deduction_num: "Deduction Quantity",

        empty: "-",

        op: "Operation",
        view: "View",
    },

    create: {
        address: {
            label: "Select Address",
            empty_tip: "No address available, please go to ",
            exist_tip: "Need a new address? Go to ",
            add_button: "Add Address",

            empty_error: "Please select an address",
            title: "Mailing Address",
        },
        type: {
            label: "Select order type",
            part: "Part Order",
            product: "Product Order",
        },

        list: {
            add: "Add Product",
            label: "Select Product",
            version_tip: "Please select a version",
            version_empty_tip: "This product has no version",
            version_new: "New version",

            parts_name: "Please select parts",
            empty_error: "Please select a product and version number",
            parts_empty_tip: "Please select a product and version first, then",
            parts_select_tip: "No parts selected yet, click",
            accessories_empty_tip: "No accessories selected, click",

            custom_tip: "This part is not in the product production list, but you have added it as a custom option.",
            remove_tip: "This part is in the product production list, but has been removed in your custom plan.",
            quantity_change_tip: "Required quantity: {plan_num}, Custom quantity: {num}",
        },

        no_inventory: "No inventory",
        success: "Order created successfully",
        confirm: "Confirm order",
        clear: "Clear selected",
    },

    detail: {
        part_order: "Part Order",
        remark_button: "Add Remark",
        remark_tip: "Please enter the remark content",

        order_info: "Order Information",

        create_time: "Creation Time",
        total_price: "Total Order Amount",
        receiver_name: "Recipient",
        receiver_phone: "Contact Number",
        receiver_address: "Shipping Address",
        product_list: "Product List",
        address_empty_tip: "Please complete the shipping information",

        confirm_tip: "Please confirm the order to proceed with the next steps",
        confirm_alert: "Once the order is confirmed, the information cannot be modified. Do you want to continue?",

        vouchers_tip: "{count} payment vouchers uploaded, awaiting review",
        vouchers_empty_tip: "Please upload payment vouchers for financial confirmation",
        paid_tip: "Payment has been made and production tasks are being arranged",
        producing_tip: "In production, you can check the production progress on the right",
        deliver_tip: "Goods have been shipped, you can check the tracking number on the right to track the progress",
        received_tip: "Goods have been received, please pay the final payment",
        received_alert: "Please confirm that the goods have been received. Do you want to continue?",

        finished_tip: "Order completed",

        go_pay: "Go to Pay",
        no_need_pay: "You do not need to pay",

        received_button: "Confirm Receipt",

        extra_op: "Extra Operations",

        deduction_fail: "Deduction of parts failed, please check if the inventory is sufficient",
        deduction_parts: "Use inventory parts",
        deduction_all_parts: "Use all inventory parts",
        order_deduction: "Order parts deduction",
    },

    list: {
        no: "Order Number",
        add: "Add Order",
        content: "Order Content",
        order_status: "Order Status",
        receive_info: "Shipping Information",
        create_time: "Order Time",

        delete_tip: "Are you sure you want to delete this order?",
    },

    log: {
        label: "Order Progress",
        express: "Express",
        produce_progress: "Production Progress",
        produce_finished: "Completed",
        produce_doing: "In Progress",
        copy_success: "Copy Successful!",

        create_order: "Create Order",
        confirm_order: "Confirm Order",
        order_express_send: "Order Shipped",
        start_produce: "Start Production",
        settle: "Order Payment Settled",
        receipt: "Order Payment Received",
        refund: "Order Refund",
        express_receipt: "Confirm Receipt",
        order_complete: "Complete Order",
        add_remark: "Add Remark",
        into_inventory: "Into Inventory",
        deduct_inventory: "Inventory deduction successful",
        revoke_deduct_inventory: "Inventory deduction revoked",
        order_close: "Order closed",
    },

    inventory: {
        in: "Order Buy",
        out: "Delivery from Storage",
        order_use: "Order Use",
        transfer: "Transfer",
        revoke_apply: "Revoke Application",
        revoke_order: "Revoke Order",
    },

    vouchers: {
        dialog_title: "Submit Payment Voucher",
        add: "Submit Payment Voucher",
        manage: "Manage Payment Vouchers",
    },
};
