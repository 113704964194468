module.exports = {
    // document title
    Title: "DeepBerry",

    // API
    API: {
        cms: "https://cms.deepberry.cn",
        titan: "https://api.deepberry.cn",
        next: "https://next.deepberry.cn",
        ws: "https://next.deepberry.cn",
    },

    // Menu
    Menu: [
        {
            title: "市场部",
            key: "mkt",
            path: "/mkt/index",
            disabled: false,
            color: "#ff8008",
        },
        {
            title: "生产部",
            key: "prod",
            path: "/prod/index",
            disabled: false,
            color: "#fdbb14",
        },
        {
            title: "财务部",
            key: "finance",
            path: "/finance/index",
            disabled: false,
            color: "#F56C6C",
        },
        {
            title: "产品部",
            key: "pms",
            path: "/pms/index",
            disabled: false,
            color: "#F56C6C",
        },
        {
            title: "综管部",
            key: "cms",
            path: "/cms/index",
            disabled: false,
            color: "#67C23A",
        },
        {
            title: "技术部",
            key: "dev",
            path: "/dev/index",
            disabled: false,
            color: "#409EFF",
        },
        {
            title: "OEM客户中心",
            key: "oem",
            path: "/oem/index",
            disabled: false,
            color: "#9254DE",
        },
        {
            title: "欧陆管理中心",
            key: "eurofins",
            path: "/eurofins/index",
            disabled: false,
            color: "#9254DE",
        },
    ],

    // Panel
    Panel: [{ title: "Test", path: "/test", disabled: true }],

    // Token
    tokenKey: "DEEPBERRY_CMS_TOKEN",
    tokenExpires: 2592000000, // 30 days
};
