/*
 * @Author: iRuxu
 * @Date: 2022-06-16 18:32:38
 * @LastEditTime: 2022-07-13 18:27:30
 * @Description: 通用请求器
 */

import { installStandardInterceptors } from "./interceptors.js";
import axios from "axios";
import User from "./user.js";
import * as signalR from "@microsoft/signalr";
const { tokenKey } = require("@/settings.js");

// cms通用请求接口
function $cms(options) {
    let domain = (options && options.domain) || process.env.VUE_APP_CMS_API;
    let config = {
        // 同时发送cookie和basic auth
        withCredentials: true,
        auth: {
            username: "admin",
            password: (localStorage && localStorage.getItem(tokenKey)) || "",
        },
        baseURL: domain,
        headers: {},
    };

    if (options && options.progress) {
        config.onUploadProgress = options.progress;
    }

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

// titan通用请求接口
function $titan(options) {
    const domain = (options && options.domain) || process.env.VUE_APP_TITAN_API;
    const config = {
        // 同时发送cookie和basic auth
        withCredentials: true,
        auth: {
            username: "admin",
            password: (localStorage && localStorage.getItem(tokenKey)) || "",
        },
        baseURL: domain,
        headers: {},
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

// next接口请求构建器
function $next(options) {
    const domain = process.env["NODE_ENV"] == "development" ? "/" : (options && options.domain) || process.env.VUE_APP_NEXT_API;

    const config = {
        withCredentials: true,
        baseURL: domain,
        headers: {},
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

/**
 * io socket
 *
 * @param {*} url
 * @return {*}
 */
function $ios(url) {
    const socketApiUrl = process.env.VUE_APP_WS_API;

    return new signalR.HubConnectionBuilder()
        .withUrl(`${socketApiUrl}/${url}`, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: () => User.getToken(),
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (_retryContext) => {
                return 5000;
            },
        })
        .build();
}

/**
 * io socket
 *
 * @param {*} url
 * @return {*}
 */
function $nexts(url) {
    // const socketApiUrl = "http://192.168.5.87:5000/";
    // const socketApiUrl = "https://dev.next.deepberry.cn/";
    const socketApiUrl = process.env.VUE_APP_WS_API;

    return new signalR.HubConnectionBuilder()
        .withUrl(`${socketApiUrl}${url}`, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: () => User.getToken({ version: 2 }),
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (_retryContext) => {
                return 5000;
            },
        })
        .build();
}

function $pay(options) {
    let domain = (options && options.domain) || process.env.VUE_APP_PAY_API;
    let config = {
        // 同时发送cookie和basic auth
        withCredentials: true,
        auth: {
            username: "admin",
            password: (localStorage && localStorage.getItem(tokenKey)) || "",
        },
        baseURL: domain,
        headers: {},
    };

    if (options && options.progress) {
        config.onUploadProgress = options.progress;
    }

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

export { $cms, $titan, $next, $ios, $nexts, $pay };
